import { LoadingButton } from '@mui/lab'
import { Grid, Paper, Toolbar, Typography } from '@mui/material'
import axios from 'axios'
import { useState, useEffect, ReactNode, useCallback, useMemo } from 'react'

import Loader from '../../../../../_common/_components/Loader/Loader'
import { EFundsType } from '../../../../../_enums'
import { FundTypes } from '../../../../../_enums/EFundsType'
import {
  getProductById,
  EProductFamilies,
} from '../../../../../_enums/EProduct'
import ESeverity from '../../../../../_enums/ESeverity'
import { useToaster } from '../../../../../_hooks'
import { IllustrationStore } from '../../../../../types/salesTools'
import { useIllustrationContext } from '../../IllustrationContext'
import BenefitChart from '../../charts/Benefit'
import NonGuaranteedAgregatePolicyValuesComparisonChart from '../../charts/NonGuaranteedAgregatePolicyValuesComparison'
import NonGuaranteedPolicyValuesChart from '../../charts/NonGuaranteedPolicyValues'
import WithdrawalsChart from '../../charts/Withdrawals'
import CreditingStrategyTable from '../../tables/CreditingStrategy'
import NonGuaranteedPolicyValuesComparisonTable from '../../tables/NonGuaranteedPolicyValuesComparison'
import PolicyValuesExtended from '../../tables/PolicyValuesExtended'
import './Illustration.css'
import Summary from './Summary'

function getProductFamily(productId) {
  const product = getProductById(productId)
  return product.product_family
}

function isVega(productId) {
  const product = getProductById(productId)
  return product.product_family === EProductFamilies.Vega
}

type Props = {
  actions: (
    isLoading?: boolean,
    options?: { next?: ReactNode; back?: ReactNode }
  ) => ReactNode
  handleNext: () => void
}

function getRiderName(store: IllustrationStore) {
  return !store.riderConfigId
    ? ''
    : store.riders?.find(x => x.rider_config_id === store.riderConfigId)
        ?.rider_name
}

export function Illustration({ actions, handleNext }: Props) {
  const { toast } = useToaster()
  const { store } = useIllustrationContext()

  const [data, setData] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  const queryAPI = useCallback(() => {
    const product = getProductFamily(store.selectedConfig.plan.product.id)

    let payload = structuredClone(store)
    delete payload.riders

    let url = `${window._env_.REACT_APP_API_URL}/illustration/annuity/${product}`
    const idsArr = payload.plan.split('_')

    payload = {
      ...payload,
      plan: idsArr[0],
      product: idsArr[1],
    }

    axios
      .post(url, payload)
      .then(response => {
        const { data } = response

        // pop the last element of the array for the totals
        data.recentTableTotal = data.recentTable.pop()
        data.lowTableTotal = data.lowTable.pop()
        data.highTableTotal = data.highTable.pop()

        setData(data)
      })
      .catch(error => console.log(error))
  }, [store])

  useEffect(() => {
    window.scrollTo(0, 0)
    queryAPI()
  }, [queryAPI])

  const printPDF = product => {
    const productFamily = getProductFamily(product.id)
    let data = store
    let url = `${window._env_.REACT_APP_API_URL}/illustration/annuity/${productFamily}?print`
    const idsArr = data.plan.split('_')

    data = {
      ...data,
      plan: idsArr[0],
      product: idsArr[1],
    }

    setLoading(true)

    axios
      .post(url, data, { responseType: 'blob' })
      .then(response => {
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
          ;(window.navigator as any).msSaveOrOpenBlob(
            new Blob([response.data], { type: 'application/pdf' }),
            'filename.pdf'
          )
          return
        }

        const file = new Blob([response.data], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL)
      })
      .catch(error => {
        toast(ESeverity.Error, 'Could not retrieve data from server')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const displayFeatureLineChart = (shouldDisplay, chartTitle, data) => {
    if (!shouldDisplay && (!data || !data.length)) return null
    return (
      <Grid item xs={12}>
        <Paper sx={{ p: 2, paddingTop: 0 }}>
          <Toolbar sx={{ marginBottom: 2 }}>
            <Typography
              variant="h6"
              id="nonGuaranteedAggregatePolicyValuesTitle"
              component="div"
            >
              {chartTitle}
            </Typography>
          </Toolbar>

          <Grid container>
            <Grid item xs={12}>
              <BenefitChart data={data} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    )
  }

  const displayWithdrawalsChart = (shouldDisplay, data) => {
    if (!shouldDisplay) return null
    return (
      <Grid item xs={12}>
        <Paper sx={{ p: 2, paddingTop: 0 }}>
          <Toolbar sx={{ marginBottom: 2 }}>
            <Typography
              variant="h6"
              id="nonGuaranteedAggregatePolicyValuesTitle"
              component="div"
            >
              Non-Guaranteed Aggregate Withdrawals{' '}
              <small>(Index Period Comparison - Most Recent, Low, High)</small>
            </Typography>
          </Toolbar>

          <Grid container>
            <Grid item xs={12}>
              <WithdrawalsChart data={data} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    )
  }

  const { ownerAge, jointOwnerAge, selectedConfig, withdrawals } = store

  const riderName = useMemo(() => getRiderName(store), [])

  const {
    crediting_strategies: creditingStrategies,
    recentTable,
    recentTableTotal,
    lowTable,
    lowTableTotal,
    highTable,
    highTableTotal,
    ngpvTable,
    gpvTable,
    barChartData: { data: barChartData },
    multiLineChartData: { data: multiLineChartData },
    horizontalBarChartDataMostRecent10: {
      data: horizontalBarChartDataMostRecent10,
    },
    horizontalBarChartDataLow10: { data: horizontalBarChartDataLow10 },
    horizontalBarChartDataHigh10: { data: horizontalBarChartDataHigh10 },
  } = {
    crediting_strategies: [],
    recentTable: [],
    recentTableTotal: 0,
    lowTable: [],
    lowTableTotal: 0,
    highTable: [],
    highTableTotal: 0,
    ngpvTable: [],
    gpvTable: [],
    barChartData: { data: [] },
    multiLineChartData: { data: [] },
    horizontalBarChartDataMostRecent10: { data: [] },
    horizontalBarChartDataLow10: { data: [] },
    horizontalBarChartDataHigh10: { data: [] },
    ...data,
  } as any

  const multiLineBenefitChartData = data?.multiLineBenefitChartData?.data ?? []
  const projectedIncomeValueChartData =
    data?.projectedIncomeValueChartData?.data ?? []

  const includeLifetime = !!selectedConfig.data.withdrawal.lifetime
  const ngpvTableTotal =
    ngpvTable.length > 0 ? ngpvTable[ngpvTable.length - 1] : 0
  const ngpvTableData = ngpvTable.slice(0, -1).map((elem, index) => {
    return {
      ...elem,
      /*SAMB-1122*/
      icr: index || elem.icr ? elem.icr : 'n/a',
      interest: index || elem.interest ? elem.interest : 'n/a',
      benefit_rate: index || elem.benefit_rate ? elem.benefit_rate : 'n/a',
      income_credit: index || elem.income_credit ? elem.income_credit : 'n/a',
    }
  })

  const gpvTableTotal = gpvTable.length > 0 ? gpvTable[gpvTable.length - 1] : 0
  const gpvTableData = gpvTable.slice(0, -1).map((elem, index) => {
    return {
      ...elem,
      /*SAMB-1122*/
      icr: index || elem.icr ? elem.icr : 'n/a',
      interest: index || elem.interest ? elem.interest : 'n/a',
      benefit_rate: index || elem.benefit_rate ? elem.benefit_rate : 'n/a',
      income_credit: index || elem.income_credit ? elem.income_credit : 'n/a',
    }
  })

  const { product } = selectedConfig.plan
  const _isVega = isVega(product.id)
  const _hasIncomeValue = riderName === 'Evolve'
  const yearsInWithdrawalGraph = data?.yearsInWithdrawalGraph
  const includeWellness = data?.includeWellnessWithdrawal

  if (!data)
    return (
      <Loader
        title={`Loading ${product.name} Illustration`}
        subtitle="Building up charts"
      />
    )

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} marginTop={2}>
          <Summary
            age={store.ownerAge!}
            ownerName={store.ownerName}
            premium={store.initialPremium as any}
            product={`${store.selectedConfig.plan.product.name} ${store.selectedConfig.plan.name} ${riderName}`}
            state={store.selectedConfig.states[0].name}
            taxQualificationStatus={
              store.typeOfFunds === EFundsType.Qualified
                ? FundTypes.Qualified.name
                : FundTypes.NonQualified.name
            }
          />
        </Grid>
        <Grid item xs={12}>
          <CreditingStrategyTable data={creditingStrategies} />
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, paddingTop: 0 }}>
            <Toolbar sx={{ marginBottom: 2 }}>
              <Typography
                variant="h6"
                id="nonGuaranteedAggregatePolicyValuesTitle"
                component="div"
              >
                Non-Guaranteed Aggregate Policy Values{' '}
                <small>
                  (Theoretical Historical Index Period Comparison - Most Recent,
                  Low, High)
                </small>
              </Typography>
            </Toolbar>

            <Grid container>
              <Grid item xs={12}>
                <NonGuaranteedPolicyValuesChart data={multiLineChartData} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* we really should do something like: _currentProduct.hasFeature("IncomeValue") instead of this funky check */}
        {displayFeatureLineChart(
          _hasIncomeValue && !!projectedIncomeValueChartData,
          'Projected Income Value Based on Current Adjustments and Allocations over Various Time Periods',
          projectedIncomeValueChartData
        )}
        {displayFeatureLineChart(
          _isVega,
          'Projected Benefit Value Based on Current Adjustments and Allocations over Various Time Periods',
          multiLineBenefitChartData
        )}

        {displayWithdrawalsChart(
          withdrawals[0]?.type !== 'none' &&
            withdrawals[0]?.start <= yearsInWithdrawalGraph,
          barChartData
        )}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, paddingTop: 0 }}>
            <Toolbar sx={{ marginBottom: 2 }}>
              <Typography
                variant="h6"
                id="nonGuaranteedAggregatePolicyValuesTitle"
                component="div"
              >
                Non-Guaranteed Aggregate Policy Values{' '}
                <small>
                  (Theoretical Historical Index Period Comparison - Most Recent,
                  Low, High)
                </small>
              </Typography>
            </Toolbar>
            <Grid container spacing={2}>
              <Grid item lg={4} xs={12}>
                Most Recent 10 Years
                <NonGuaranteedPolicyValuesComparisonTable
                  data={recentTable}
                  dataTotal={recentTableTotal}
                  isVega={_isVega}
                  hasIncomeValue={_hasIncomeValue}
                  headerStyles={{
                    backgroundColor: 'rgba(43,163,218)',
                  }}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                Low 10 Years
                <NonGuaranteedPolicyValuesComparisonTable
                  data={lowTable}
                  dataTotal={lowTableTotal}
                  isVega={_isVega}
                  hasIncomeValue={_hasIncomeValue}
                  headerStyles={{
                    backgroundColor: 'rgba(179,179,179)',
                  }}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                High 10 Years
                <NonGuaranteedPolicyValuesComparisonTable
                  data={highTable}
                  dataTotal={highTableTotal}
                  isVega={_isVega}
                  hasIncomeValue={_hasIncomeValue}
                  headerStyles={{
                    backgroundColor: 'rgba(64,64,64)',
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, paddingTop: 0 }}>
            <Toolbar sx={{ marginBottom: 2 }}>
              <Typography
                variant="h6"
                id="nonGuaranteedAggregatePolicyValuesTitle"
                component="div"
              >
                Non-Guaranteed Aggregate Policy Values{' '}
                <small>
                  (Theoretical Historical Index Period Comparison - Most Recent,
                  Low, High)
                </small>
              </Typography>
            </Toolbar>
            <Grid container spacing={2}>
              <Grid item lg={4} md={12}>
                Most Recent 10 Years
                <NonGuaranteedAgregatePolicyValuesComparisonChart
                  data={horizontalBarChartDataMostRecent10}
                />
              </Grid>
              <Grid item lg={4} md={12}>
                Low 10 Years
                <NonGuaranteedAgregatePolicyValuesComparisonChart
                  data={horizontalBarChartDataLow10}
                />
              </Grid>
              <Grid item lg={4} md={12}>
                High 10 Years
                <NonGuaranteedAgregatePolicyValuesComparisonChart
                  data={horizontalBarChartDataHigh10}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} className="illustration__table">
          <PolicyValuesExtended
            title="Non-Guaranteed Policy Values"
            store={store}
            data={ngpvTableData}
            footerData={ngpvTableTotal}
            isVega={_isVega}
            hasIncomeValue={_hasIncomeValue}
            includeLifetime={includeLifetime}
            ownerAge={ownerAge}
            jointOwnerAge={jointOwnerAge}
            includeWellness={includeWellness}
          />
        </Grid>
        <Grid item xs={12} className="illustration__table">
          <PolicyValuesExtended
            title="Guaranteed Policy Values"
            store={store}
            data={gpvTableData}
            footerData={gpvTableTotal}
            isVega={_isVega}
            hasIncomeValue={_hasIncomeValue}
            includeLifetime={includeLifetime}
            ownerAge={ownerAge}
            jointOwnerAge={jointOwnerAge}
            includeWellness={includeWellness}
          />
        </Grid>
      </Grid>
      {actions(false, {
        next: (
          <LoadingButton
            variant="contained"
            disableElevation
            onClick={() => {
              printPDF(product)
            }}
            loading={loading}
          >
            Print PDF
          </LoadingButton>
        ),
      })}
    </>
  )
}
