import { Alert } from '@mui/material'
import { useEffect } from 'react'

import { useStore } from '../../_common/_hooks/use-store'
import { GetEnvironment } from '../../_common/_store/actions/policy-actions'

function EnviromentVariablesAlerts() {
  const { store, dispatch } = useStore()

  useEffect(() => {
    ;(async function () {
      dispatch?.(await GetEnvironment())
    })()
  }, [dispatch])

  const version = store?.environment?.version

  if (
    !version ||
    (version.hasOwnProperty('should_show_environment_banner') &&
      !version.should_show_environment_banner)
  )
    return null

  if (version?.error)
    return (
      <Alert severity="error" sx={{ mt: 1 }}>
        {version?.error}
      </Alert>
    )

  return (
    <>
      <Alert severity="warning" sx={{ mt: 1 }}>
        Database: <b>{version?.db_name?.toUpperCase()}</b>
      </Alert>
      <Alert severity="warning" sx={{ mt: 1 }}>
        Environment: <b>{version?.server_env}</b>
      </Alert>
      <Alert severity="warning" sx={{ mt: 1 }}>
        Beta Features:{' '}
        <b>{version?.beta_features_default_active === true ? 'Yes' : 'No'}</b>
      </Alert>
    </>
  )
}

export default EnviromentVariablesAlerts
