import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Grid } from '@mui/material'
import { Link, NavLink } from 'react-router-dom'

import { useStore } from '../../_common/_hooks/use-store'

const SidebarItems = ({ items, setMenuOpen }) => {
  const { store } = useStore()
  const _items = items.filter(
    x =>
      (x.isBetaFeature &&
        store.environment?.version.beta_features_default_active) ||
      !x.isBetaFeature
  )

  return (
    <Grid container flexDirection="column" rowSpacing={1}>
      {_items.map(({ icon, linkTarget, text }) => (
        <Grid key={linkTarget} item>
          <NavLink
            onClick={() => setMenuOpen?.(old => !old)}
            exact
            to={linkTarget!}
            style={isActive => ({
              color: 'white',
              ...(isActive
                ? {
                    color: 'black',
                    backgroundColor: 'white',
                  }
                : null),
            })}
          >
            <Box
              display="flex"
              alignItems="center"
              height={48}
              paddingLeft={4}
              paddingRight={4}
              width="100%"
              className="link"
            >
              <FontAwesomeIcon icon={icon! as IconProp} /> {text}
            </Box>
          </NavLink>
        </Grid>
      ))}

      <Grid item>
        <Link
          onClick={() => setMenuOpen?.(old => !old)}
          to={{
            pathname: 'http://www.SILACins.com/CCPA-PrivacyPolicy',
          }}
          target="_blank"
        >
          <Box
            display="flex"
            alignItems="center"
            height={48}
            paddingLeft={4}
            paddingRight={4}
            width="100%"
            className="link"
            sx={{ color: 'white !important' }}
          >
            <FontAwesomeIcon icon="gavel" /> Privacy Policy
          </Box>
        </Link>
      </Grid>
    </Grid>
  )
}

export default SidebarItems
