let user = JSON.parse(localStorage.getItem('user'))
const initialState = user ? { loggedIn: true, user } : {}

export function authentication(state = initialState, action) {
  switch (action.type) {
    case 'USERS_LOGIN_REQUEST':
      return {
        ...action.payload,
        loggingIn: true,
        loggedIn: false,
        showAlert: false,
        alertMsg: '',
      }
    case 'USERS_LOGIN_SUCCESS':
      return {
        ...action.payload,
        loggingIn: false,
        loggedIn: true,
        showAlert: false,
        alertMsg: '',
      }
    case 'USERS_LOGIN_FAILURE':
      return {
        ...action.payload,
        loggingIn: false,
        loggedIn: false,
        showAlert: true,
        alertMsg: 'Username or Password Incorrect.',
      }
    case 'USERS_LOGOUT':
      return {
        ...action.payload,
        loggingIn: false,
        showAlert: true,
        alertMsg: 'You are Logged Out',
        loggedIn: false,
        username: '',
        password: '',
        submitted: false,
      }
    case 'USERS_PASSWORD_RESET_REQUEST':
      return {
        ...action.payload,
        loggingIn: false,
        showAlert: true,
        alertMsg: 'Email Request Sent',
        loggedIn: false,
        username: '',
        password: '',
        submitted: false,
      }
    case 'USERS_PASSWORD_RESET_REQUEST_FAILURE':
      return {
        ...action.payload,
        loggingIn: false,
        showAlert: true,
        alertMsg:
          'There is no active user associated with this e-mail address or the password can not be changed',
        loggedIn: false,
        username: '',
        password: '',
        submitted: false,
      }
    default:
      return state
  }
}
